<template>
  <div>
    <div class="pa-5">
      <div v-if="contenttype_field.type == 'text'">
        <div v-if="contenttype_field.is_translatable">
          <!-- translatable fields -->
          <v-text-field v-for="(lang, i) in $store.getters['i18n/langs']" :key="i"
            v-model="contenttype_field.model[lang.key].model" :error-messages="$t(contenttype_field.model[lang.key].error, {
              field: contenttype_field.translations[$i18n.locale].name,
              pattern_description: contenttype_field.translations[$i18n.locale].regular_expression_error,
            })
              " :label="contenttype_field.translations[$i18n.locale].name" hide-details="auto" outlined class="py-2"
            @blur="contenttype_field.model[lang.key].error = ''">
            <template v-slot:append>
              <v-img :src="lang.image" height="14px" width="22px" :alt="lang.key" class="me-2 mt-1"></v-img>
              <div class="mt-1">{{ lang.native }}</div>
            </template>
          </v-text-field>
        </div>
        <v-text-field v-else v-model="contenttype_field.model" :error-messages="$t(contenttype_field.error, {
          field: contenttype_field.translations[$i18n.locale].name,
          pattern_description: contenttype_field.translations[$i18n.locale].regular_expression_error,
        })
          " :label="contenttype_field.translations[$i18n.locale].name" hide-details="auto" outlined
          @blur="contenttype_field.error = ''" />
      </div>
      <div v-else-if="contenttype_field.type == 'textarea'">
        <div v-if="contenttype_field.is_translatable">
          <!-- translatable fields -->
          <v-textarea v-for="(lang, i) in $store.getters['i18n/langs']" :key="i"
            v-model="contenttype_field.model[lang.key].model" :error-messages="$t(contenttype_field.model[lang.key].error, {
              field: contenttype_field.translations[$i18n.locale].name,
              pattern_description: contenttype_field.translations[$i18n.locale].regular_expression_error,
            })
              " :label="contenttype_field.translations[$i18n.locale].name" rows="1" auto-grow hide-details="auto"
            outlined class="py-2" @blur="contenttype_field.model[lang.key].error = ''">
            <template v-slot:append>
              <v-img :src="lang.image" height="14px" width="22px" :alt="lang.key" class="me-2 mt-1"></v-img>
              <div class="mt-1">{{ lang.native }}</div>
            </template>
          </v-textarea>
        </div>
        <v-textarea v-else v-model="contenttype_field.model" :error-messages="$t(contenttype_field.error, {
          field: contenttype_field.translations[$i18n.locale].name,
          pattern_description: contenttype_field.translations[$i18n.locale].regular_expression_error,
        })
          " :label="contenttype_field.translations[$i18n.locale].name" rows="1" auto-grow hide-details="auto" outlined
          @blur="contenttype_field.error = ''" />
      </div>
      <div v-else-if="contenttype_field.type == 'richtext'">
        <div v-if="contenttype_field.is_translatable">
          <div v-for="(lang, i) in $store.getters['i18n/langs']" :key="i" class="mb-3">
            <v-img :src="lang.image" height="14px" width="22px" :alt="lang.key" class="mt-3 mr-3 float-right"></v-img>
            <rich-text-editor
              v-model="contenttype_field.model[lang.key].model" :error-messages="$t(contenttype_field.model[lang.key].error, {
                field: contenttype_field.translations[$i18n.locale].name,
                pattern_description: contenttype_field.translations[$i18n.locale].regular_expression_error,
              })
           
                " :title="contenttype_field.translations[$i18n.locale].name" />
         
          </div>
        </div>
        <div v-else>
          <rich-text-editor :key="i" v-model="contenttype_field.model" :error-messages="$t(contenttype_field.model.error, {
            field: contenttype_field.translations[$i18n.locale].name,
            pattern_description: contenttype_field.translations[$i18n.locale].regular_expression_error,
          })
            " :title="contenttype_field.translations[$i18n.locale].name" />
        </div>
      </div>
      <div v-else-if="contenttype_field.type == 'number'">
        <v-text-field v-model="contenttype_field.model" type="number"
          :error-messages="$t(contenttype_field.error, { field: contenttype_field.translations[$i18n.locale].name })"
          :label="contenttype_field.translations[$i18n.locale].name" hide-details="auto" outlined
          @blur="contenttype_field.error = ''" />

      </div>
      <div v-else-if="contenttype_field.type == 'datetime'">
        <datetime-field v-model="contenttype_field"
          :error-messages="$t(contenttype_field.error, { field: contenttype_field.translations[$i18n.locale].name })"
          :label="contenttype_field.translations[$i18n.locale].name" @blur="contenttype_field.error = ''" />
      </div>
      <div v-else-if="contenttype_field.type == 'boolean'">
        <v-switch v-model="contenttype_field.model" hide-details="auto"
          :label="contenttype_field.translations[$i18n.locale].name" outlined />
      </div>
      <div v-else-if="contenttype_field.type == 'options'">
        <autocomplete v-model="contenttype_field.model"
          :error-messages="$t(contenttype_field.error, { field: contenttype_field.translations[$i18n.locale].name })"
          :multiple="true" :search_api_endpoint="'contenttypes/' +
            contenttype_field.options_from_contenttype_api_identifier +
            '/entries?page=1&page_size=1000&search='
            "
          :show_api_endpoint="'contenttypes/' + contenttype_field.options_from_contenttype_api_identifier + '/entries'"
          item_value="id" :label="contenttype_field.translations[$i18n.locale].name" hide-details="auto"
          @blur="contenttype_field.error = ''" />
      </div>
      <div v-else-if="contenttype_field.type == 'option'">
        <autocomplete v-model="contenttype_field.model"
          :error-messages="$t(contenttype_field.error, { field: contenttype_field.translations[$i18n.locale].name })"
          :search_api_endpoint="'contenttypes/' +
            contenttype_field.options_from_contenttype_api_identifier +
            '/entries?page=1&page_size=1000&search='
            "
          :show_api_endpoint="'contenttypes/' + contenttype_field.options_from_contenttype_api_identifier + '/entries'"
          item_value="id" :label="contenttype_field.translations[$i18n.locale].name" hide-details="auto"
          @blur="contenttype_field.error = ''" />
      </div>
      <div v-else-if="contenttype_field.type == 'file'">
        <v-file-input v-model="contenttype_field.model" :key="file_key + '-' + contenttype_field.api_identifier"
          :id="contenttype_field.api_identifier" :ref="contenttype_field.api_identifier"
          :error-messages="$t(contenttype_field.error, { field: contenttype_field.translations[$i18n.locale].name })"
          :label="contenttype_field.translations[$i18n.locale].name" :clearable="contenttype_field.model != null"
          :accept="contenttype_field.settings.file_extentions" :multiple="contenttype_field.settings.multiple_files"
          persistent-hint truncate-length="30" :hint="contenttype_field.settings.multiple_files
              ? $t('page.contenttype_entries.click_to_select_one_or_multiple_file')
              : $t('page.contenttype_entries.click_to_select_file')
            " hide-details="auto" @blur="contenttype_field.error = ''" prepend-icon="" outlined>
          <template v-slot:selection="{ text, index, file }">
            <v-tooltip v-if="showDownload(contenttype_field.model, index)" bottom>
              <template v-slot:activator="{ on: tooltip, attrs }">
                <v-chip @click.prevent="downloadFile($event, file)" small color="primary" v-bind="attrs"
                  v-on="{ ...tooltip }">
                  {{ text }}
                </v-chip>
              </template>
              <span>{{ $t('common.download') }} {{ file.size | file_size }}</span>
            </v-tooltip>
            <v-chip v-else small color="primary">
              {{ text }}
            </v-chip>
          </template>
        </v-file-input>
      </div>
      <div v-else>{{ $t('common.not_found') }}</div>
    </div>
    <v-divider />
  </div>
</template>

<script>
import DatetimeField from '@/components/DatetimeField.vue'
import Autocomplete from '@/components/Autocomplete.vue'
import RichTextEditor from '@/components/RichTextEditor.vue'
import { mdiDownload, mdiDelete } from '@mdi/js'

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      default: 'create',
      required: false,
    },
  },

  components: {
    DatetimeField,
    Autocomplete,
    RichTextEditor,
  },

  beforeMount() {
    if (this.value.type == 'file' && this.value.model == null) {
      this.value.model = {}
    }
    this.contenttype_field = this.value
  },
  data() {
    return {
      contenttype_field: {
        error: {},
      },
      file_key: 1,

      mdiDownload,
      mdiDelete,
    }
  },
  methods: {
    downloadFile: function ($event, file) {
      $event.stopPropagation()
      this.$http({
        url:
          'contenttypes/' +
          this.$route.params.api_identifier +
          '/entries/' +
          this.$route.params.id +
          '/' +
          this.contenttype_field.id,
        params: {
          file_name: file.name,
        },
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', file.name)
        document.body.appendChild(link)
        link.click()
      })
    },
    fileSizes(files) {
      console.log(files)
      if (!Array.isArray(files)) {
        files = [files]
      }
      let bytes = 0
      files.forEach(file => {
        bytes = bytes + file.size
      })
      return bytes
    },
    showDownload(model, index) {
      let result = true
      if (model instanceof File) {
        result = false
      } else if (model[index] instanceof File) {
        result = false
      }

      return result
    },
  },
  mounted() {
    console.log(this.contenttype_field.model);
  },
  computed: {},
}
</script>