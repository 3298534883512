<template>
  <div>
    <v-text-field
      @mouseup="open"
      @keydown.stop.enter="open"
      :ref="this.value.id"
      :value="formattedDate"
      :error-messages="errorMessages"
      :label="label"
      :prepend-inner-icon="mdiCalendar"
      hide-details="auto"
      readonly
      outlined
    />
    <v-dialog v-model="dialog" persistent width="290px">
      <v-stepper flat v-model="stepper">
        <v-stepper-items>
          <v-stepper-content class="pa-0" step="1">
            <v-date-picker v-model="date" @change="dateChanges" color="primary" :locale="$i18n.locale">
              <v-row no-gutters>
                <v-col>
                  <v-btn text @click="cancel">{{ $t('common.cancel') }}</v-btn>
                </v-col>
                <v-col> </v-col>
              </v-row>
            </v-date-picker>
          </v-stepper-content>

          <v-stepper-content class="pa-0" step="2">
            <v-time-picker v-if="dialog" v-model="time" format="24hr">
              <v-row no-gutters>
                <v-col>
                  <v-btn text @click="cancel">{{ $t('common.cancel') }}</v-btn>
                </v-col>
                <v-col>
                  <v-btn :disabled="!time" text color="primary" @click="saveDate">{{ $t('common.ok') }}</v-btn>
                </v-col>
              </v-row>
            </v-time-picker>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-dialog>
  </div>
</template>

<script>
import { mdiCalendar } from '@mdi/js'

export default {
  name: 'DatetimeField',
  props: {
    value: Object,
    input_value: String,

    errorMessages: String,
    label: String,
  },

  mounted() {
    if (this.value.settings && this.value.settings.include_time) {
      this.include_time = true
    }
    if (this.value.model) {
      if (this.include_time) {
        let splitted_input = this.value.model.split(' ')
        const formatted_date = splitted_input[0].split('-')
        this.date = formatted_date[2] + '-' + formatted_date[1] + '-' + formatted_date[0]
        this.time = splitted_input[1]
      } else {
        const formatted_date = this.value.model.split('-')
        this.date = formatted_date[2] + '-' + formatted_date[1] + '-' + formatted_date[0]
      }
    }
  },

  data: () => {
    return {
      dialog: false,
      stepper: 1,
      include_time: false,

      date: '',
      time: '',

      mdiCalendar,
    }
  },
  computed: {
    formattedDate() {
      if (this.date) {
        let formatted_date = this.date.split('-')
        formatted_date = formatted_date[2] + '-' + formatted_date[1] + '-' + formatted_date[0]
        if (this.time) {
          return formatted_date + ' ' + this.time
        }
        return formatted_date
      }
      return ''
    },
  },
  methods: {
    saveDate() {
      this.dialog = false
      this.stepper = 1
      this.value.model = this.formattedDate
      this.$emit('blur')
    },
    open() {
      this.dialog = true
    },
    cancel() {
      this.dialog = false
      this.stepper = 1
      this.date = ''
      this.time = ''
    },
    dateChanges() {
      if (this.include_time) {
        this.stepper = 2
      } else {
        this.saveDate()
      }
    },
  },
}
</script>