var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pa-5"},[(_vm.contenttype_field.type == 'text')?_c('div',[(_vm.contenttype_field.is_translatable)?_c('div',_vm._l((_vm.$store.getters['i18n/langs']),function(lang,i){return _c('v-text-field',{key:i,staticClass:"py-2",attrs:{"error-messages":_vm.$t(_vm.contenttype_field.model[lang.key].error, {
            field: _vm.contenttype_field.translations[_vm.$i18n.locale].name,
            pattern_description: _vm.contenttype_field.translations[_vm.$i18n.locale].regular_expression_error,
          }),"label":_vm.contenttype_field.translations[_vm.$i18n.locale].name,"hide-details":"auto","outlined":""},on:{"blur":function($event){_vm.contenttype_field.model[lang.key].error = ''}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-img',{staticClass:"me-2 mt-1",attrs:{"src":lang.image,"height":"14px","width":"22px","alt":lang.key}}),_c('div',{staticClass:"mt-1"},[_vm._v(_vm._s(lang.native))])]},proxy:true}],null,true),model:{value:(_vm.contenttype_field.model[lang.key].model),callback:function ($$v) {_vm.$set(_vm.contenttype_field.model[lang.key], "model", $$v)},expression:"contenttype_field.model[lang.key].model"}})}),1):_c('v-text-field',{attrs:{"error-messages":_vm.$t(_vm.contenttype_field.error, {
        field: _vm.contenttype_field.translations[_vm.$i18n.locale].name,
        pattern_description: _vm.contenttype_field.translations[_vm.$i18n.locale].regular_expression_error,
      }),"label":_vm.contenttype_field.translations[_vm.$i18n.locale].name,"hide-details":"auto","outlined":""},on:{"blur":function($event){_vm.contenttype_field.error = ''}},model:{value:(_vm.contenttype_field.model),callback:function ($$v) {_vm.$set(_vm.contenttype_field, "model", $$v)},expression:"contenttype_field.model"}})],1):(_vm.contenttype_field.type == 'textarea')?_c('div',[(_vm.contenttype_field.is_translatable)?_c('div',_vm._l((_vm.$store.getters['i18n/langs']),function(lang,i){return _c('v-textarea',{key:i,staticClass:"py-2",attrs:{"error-messages":_vm.$t(_vm.contenttype_field.model[lang.key].error, {
            field: _vm.contenttype_field.translations[_vm.$i18n.locale].name,
            pattern_description: _vm.contenttype_field.translations[_vm.$i18n.locale].regular_expression_error,
          }),"label":_vm.contenttype_field.translations[_vm.$i18n.locale].name,"rows":"1","auto-grow":"","hide-details":"auto","outlined":""},on:{"blur":function($event){_vm.contenttype_field.model[lang.key].error = ''}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-img',{staticClass:"me-2 mt-1",attrs:{"src":lang.image,"height":"14px","width":"22px","alt":lang.key}}),_c('div',{staticClass:"mt-1"},[_vm._v(_vm._s(lang.native))])]},proxy:true}],null,true),model:{value:(_vm.contenttype_field.model[lang.key].model),callback:function ($$v) {_vm.$set(_vm.contenttype_field.model[lang.key], "model", $$v)},expression:"contenttype_field.model[lang.key].model"}})}),1):_c('v-textarea',{attrs:{"error-messages":_vm.$t(_vm.contenttype_field.error, {
        field: _vm.contenttype_field.translations[_vm.$i18n.locale].name,
        pattern_description: _vm.contenttype_field.translations[_vm.$i18n.locale].regular_expression_error,
      }),"label":_vm.contenttype_field.translations[_vm.$i18n.locale].name,"rows":"1","auto-grow":"","hide-details":"auto","outlined":""},on:{"blur":function($event){_vm.contenttype_field.error = ''}},model:{value:(_vm.contenttype_field.model),callback:function ($$v) {_vm.$set(_vm.contenttype_field, "model", $$v)},expression:"contenttype_field.model"}})],1):(_vm.contenttype_field.type == 'richtext')?_c('div',[(_vm.contenttype_field.is_translatable)?_c('div',_vm._l((_vm.$store.getters['i18n/langs']),function(lang,i){return _c('div',{key:i,staticClass:"mb-3"},[_c('v-img',{staticClass:"mt-3 mr-3 float-right",attrs:{"src":lang.image,"height":"14px","width":"22px","alt":lang.key}}),_c('rich-text-editor',{attrs:{"error-messages":_vm.$t(_vm.contenttype_field.model[lang.key].error, {
              field: _vm.contenttype_field.translations[_vm.$i18n.locale].name,
              pattern_description: _vm.contenttype_field.translations[_vm.$i18n.locale].regular_expression_error,
            }),"title":_vm.contenttype_field.translations[_vm.$i18n.locale].name},model:{value:(_vm.contenttype_field.model[lang.key].model),callback:function ($$v) {_vm.$set(_vm.contenttype_field.model[lang.key], "model", $$v)},expression:"contenttype_field.model[lang.key].model"}})],1)}),0):_c('div',[_c('rich-text-editor',{key:_vm.i,attrs:{"error-messages":_vm.$t(_vm.contenttype_field.model.error, {
          field: _vm.contenttype_field.translations[_vm.$i18n.locale].name,
          pattern_description: _vm.contenttype_field.translations[_vm.$i18n.locale].regular_expression_error,
        }),"title":_vm.contenttype_field.translations[_vm.$i18n.locale].name},model:{value:(_vm.contenttype_field.model),callback:function ($$v) {_vm.$set(_vm.contenttype_field, "model", $$v)},expression:"contenttype_field.model"}})],1)]):(_vm.contenttype_field.type == 'number')?_c('div',[_c('v-text-field',{attrs:{"type":"number","error-messages":_vm.$t(_vm.contenttype_field.error, { field: _vm.contenttype_field.translations[_vm.$i18n.locale].name }),"label":_vm.contenttype_field.translations[_vm.$i18n.locale].name,"hide-details":"auto","outlined":""},on:{"blur":function($event){_vm.contenttype_field.error = ''}},model:{value:(_vm.contenttype_field.model),callback:function ($$v) {_vm.$set(_vm.contenttype_field, "model", $$v)},expression:"contenttype_field.model"}})],1):(_vm.contenttype_field.type == 'datetime')?_c('div',[_c('datetime-field',{attrs:{"error-messages":_vm.$t(_vm.contenttype_field.error, { field: _vm.contenttype_field.translations[_vm.$i18n.locale].name }),"label":_vm.contenttype_field.translations[_vm.$i18n.locale].name},on:{"blur":function($event){_vm.contenttype_field.error = ''}},model:{value:(_vm.contenttype_field),callback:function ($$v) {_vm.contenttype_field=$$v},expression:"contenttype_field"}})],1):(_vm.contenttype_field.type == 'boolean')?_c('div',[_c('v-switch',{attrs:{"hide-details":"auto","label":_vm.contenttype_field.translations[_vm.$i18n.locale].name,"outlined":""},model:{value:(_vm.contenttype_field.model),callback:function ($$v) {_vm.$set(_vm.contenttype_field, "model", $$v)},expression:"contenttype_field.model"}})],1):(_vm.contenttype_field.type == 'options')?_c('div',[_c('autocomplete',{attrs:{"error-messages":_vm.$t(_vm.contenttype_field.error, { field: _vm.contenttype_field.translations[_vm.$i18n.locale].name }),"multiple":true,"search_api_endpoint":'contenttypes/' +
          _vm.contenttype_field.options_from_contenttype_api_identifier +
          '/entries?page=1&page_size=1000&search=',"show_api_endpoint":'contenttypes/' + _vm.contenttype_field.options_from_contenttype_api_identifier + '/entries',"item_value":"id","label":_vm.contenttype_field.translations[_vm.$i18n.locale].name,"hide-details":"auto"},on:{"blur":function($event){_vm.contenttype_field.error = ''}},model:{value:(_vm.contenttype_field.model),callback:function ($$v) {_vm.$set(_vm.contenttype_field, "model", $$v)},expression:"contenttype_field.model"}})],1):(_vm.contenttype_field.type == 'option')?_c('div',[_c('autocomplete',{attrs:{"error-messages":_vm.$t(_vm.contenttype_field.error, { field: _vm.contenttype_field.translations[_vm.$i18n.locale].name }),"search_api_endpoint":'contenttypes/' +
          _vm.contenttype_field.options_from_contenttype_api_identifier +
          '/entries?page=1&page_size=1000&search=',"show_api_endpoint":'contenttypes/' + _vm.contenttype_field.options_from_contenttype_api_identifier + '/entries',"item_value":"id","label":_vm.contenttype_field.translations[_vm.$i18n.locale].name,"hide-details":"auto"},on:{"blur":function($event){_vm.contenttype_field.error = ''}},model:{value:(_vm.contenttype_field.model),callback:function ($$v) {_vm.$set(_vm.contenttype_field, "model", $$v)},expression:"contenttype_field.model"}})],1):(_vm.contenttype_field.type == 'file')?_c('div',[_c('v-file-input',{key:_vm.file_key + '-' + _vm.contenttype_field.api_identifier,ref:_vm.contenttype_field.api_identifier,attrs:{"id":_vm.contenttype_field.api_identifier,"error-messages":_vm.$t(_vm.contenttype_field.error, { field: _vm.contenttype_field.translations[_vm.$i18n.locale].name }),"label":_vm.contenttype_field.translations[_vm.$i18n.locale].name,"clearable":_vm.contenttype_field.model != null,"accept":_vm.contenttype_field.settings.file_extentions,"multiple":_vm.contenttype_field.settings.multiple_files,"persistent-hint":"","truncate-length":"30","hint":_vm.contenttype_field.settings.multiple_files
            ? _vm.$t('page.contenttype_entries.click_to_select_one_or_multiple_file')
            : _vm.$t('page.contenttype_entries.click_to_select_file'),"hide-details":"auto","prepend-icon":"","outlined":""},on:{"blur":function($event){_vm.contenttype_field.error = ''}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var text = ref.text;
            var index = ref.index;
            var file = ref.file;
return [(_vm.showDownload(_vm.contenttype_field.model, index))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var tooltip = ref.on;
            var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"small":"","color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.downloadFile($event, file)}}},'v-chip',attrs,false),Object.assign({}, tooltip)),[_vm._v(" "+_vm._s(text)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.download'))+" "+_vm._s(_vm._f("file_size")(file.size)))])]):_c('v-chip',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.contenttype_field.model),callback:function ($$v) {_vm.$set(_vm.contenttype_field, "model", $$v)},expression:"contenttype_field.model"}})],1):_c('div',[_vm._v(_vm._s(_vm.$t('common.not_found')))])]),_c('v-divider')],1)}
var staticRenderFns = []

export { render, staticRenderFns }