<template>
  <v-autocomplete
    v-model="model"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    hide-no-data
    :item-text="item_text || $t('common.not_found')"
    :item-value="item_value"
    :label="label"
    :error-messages="errorMessages"
    hide-details="auto"
    :placeholder="$t('common.start_typing_search')"
    deletable-chips
    :multiple="multiple"
    chips
    @blur="$emit('blur')"
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'Autocomplete',
  props: {
    //v-model
    value: {},
    //Naar welke api_endpoint dit component mag roepen voor het zoeken van items
    search_api_endpoint: {
      type: String,
      required: true,
    },
    //Naar welke api dit component mag roepen voor het ophalen van de meegegeven data
    show_api_endpoint: {
      type: String,
      required: true,
    },
    //Label
    label: {
      type: String,
      required: true,
    },

    errorMessages: String,

    //Welke property wordt visueel getoond
    item_text: {
      type: String,
      default: 'title',
    },
    //Welke property wordt in het model gezet een item is geselecteerd
    item_value: {
      type: String,
      default: 'id',
    },
    //Zijn er meerdere selecterbaar
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  beforeMount() {
    if (this.value) {
      if (Array.isArray(this.value)) {
        this.model = []
        this.value.forEach(item => {
          this.$http(this.show_api_endpoint + '/' + item).then(res => {
            this.entries.push(res.data)
            this.model.push(res.data[this.item_value])
          })
        })
      } else {
        this.$http(this.show_api_endpoint + '/' + this.value).then(res => {
          this.entries.push(res.data)
          this.model = res.data[this.item_value]
        })
      }
    }
  },
  mounted() {
    this.sendSearchRequest()
  },
  data: () => {
    return {
      entries: [],
      loading: false,
      next_request_scheduled: false,
      timing_out: false,
      model: null,
      search: null,
    }
  },
  computed: {
    items() {
      let result = JSON.parse(JSON.stringify(this.entries))
      console.log(result);
      if (this.model) {
        if (Array.isArray(this.model)) {
          this.model.forEach(item => {
            result.push(item)
          })
        } else {
          result.push(this.model)
        }
      }
      return result
    },
  },
  methods: {
    sendSearchRequest() {
      // if (!this.search) {
      //   return
      // }

      if (this.loading || this.timing_out) {
        if (!this.next_request_scheduled) {
          this.next_request_scheduled = true
          setTimeout(() => {
            this.next_request_scheduled = false
            this.sendSearchRequest()
          }, 1000)
        }
        return
      }

      this.loading = true

      // Lazily load input items
      this.$http(this.search_api_endpoint + this.search)
        .then(res => {
          this.entries = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false

          this.timing_out = true
          setTimeout(() => {
            this.timing_out = false
          }, 1000)
        })
    },
  },
  watch: {
    search(val) {
      this.sendSearchRequest()
    },
    model(val) {
      if (val) {
        this.$emit('input', val)
      } else {
        this.$emit('input', '')
      }
    },
  },
}
</script>